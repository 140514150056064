<template>
  <div>
    <h1
      class="text-3xl font-semibold p-6 border-b border-solid border-gray-200"
    >
      Настройки
    </h1>
    <div class="settings-container">
      <div
        v-for="(chapter, index) in settingsChapters"
        :key="index"
        class="settings-box"
        @click="openSettings(chapter.route)"
      >
        <h3 class="font-semibold">{{ chapter.title }}</h3>
        <p>{{ chapter.description }}</p>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      settingsChapters: [
        {
          title: "Настроить партия файлов",
          description: "Вы можете включить и выключить партии файлов",
          route: "batches",
        },
        {
          title: "Загрузить файл",
          description: "Вы можете загрузить файл",
          route: "upload",
        },
      ],
    };
  },
  methods: {
    openSettings(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped lang="scss">
.settings-container {
  @apply flex flex-wrap gap-3.5 p-6;
}
.settings-box {
  width: 400px;
  @apply border border-solid border-gray-200 rounded bg-gray-50 cursor-pointer;
  padding: 1rem;
}
.settings-box:hover {
  transform: scale(1.01);
}
h3 {
  font-size: 18px;
}
p {
  @apply font-thin text-gray-600;
  margin-top: 15px;
  font-size: 13.5px;
}
</style>
